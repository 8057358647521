<template>
  <b-overlay
    :variant="'transparent'"
    :opacity="1.00"
    :blur="'1px'"
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>{{ $t("Statistic") }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  <ICountUp
                    :delay="delay"
                    :end-val="item.title"
                    :options="options"
                  />
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,

  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import ICountUp from 'vue-countup-v2'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BOverlay,
    ICountUp,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      show: false,

      data: [
        {
          icon: 'ShoppingCartIcon',
          color: 'light-info',
          title: 0,
          subtitle: 'Pedidos',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Pagos',
          customClass: 'mb-2 mb-xl-0',
        },

        {
          icon: 'CreditCardIcon',
          color: 'light-danger',
          title: 0,
          subtitle: 'Devoluciones',
          customClass: '',
        },
        {
          icon: 'ShoppingBagIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Productos',
          customClass: '',
        },
        {
          icon: 'FolderIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Categorías',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      vendor: '',
      userData: getUserData(),
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    }
  },
  mounted() {
    try {
      this.vendor = this.userData.profile.profileVendor.edges[0].node.id
    } catch (error) {
      this.vendor = ''
    }
    this.getTotals()
  },
  methods: {
    getTotals() {
      this.show = true

      const userData = getUserData()
      axios.post('', {
        query: `
        {
              allVendors(id:"${this.vendor}",client:"${userData.profile.client.id}") {
                edges {
                  node {                   
                    categoryProductsVendor {
                      totalCount                    
                    }
                    productVendor {
                      totalCount
                    }
                    vendorVendorPay {
                    totalCount
                    }
                    cartVendor {
                      totalCount          
                    } 
                    vendorRefund {
                      totalCount        
                    }
                  }
                }
              }
            }
      `,
      })
        .then(res => {
          messageError(res, this)

          const dataRes = res.data.data.allVendors.edges[0].node
          this.data = [

            {
              icon: 'ShoppingCartIcon',
              color: 'light-info',
              title: dataRes.cartVendor.totalCount,
              subtitle: 'Pedidos',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-primary',
              title: dataRes.vendorVendorPay.totalCount,
              subtitle: 'Pagos',
              customClass: 'mb-2 mb-xl-0',
            },

            {
              icon: 'CreditCardIcon',
              color: 'light-danger',
              title: dataRes.vendorRefund.totalCount,
              subtitle: 'Devoluciones',
              customClass: '',
            },
            {
              icon: 'ShoppingBagIcon',
              color: 'light-success',
              title: dataRes.productVendor.totalCount,
              subtitle: 'Productos',
              customClass: '',
            },
            {
              icon: 'FolderIcon',
              color: 'light-success',
              title: dataRes.categoryProductsVendor.totalCount,
              subtitle: 'Categorías',
              customClass: 'mb-2 mb-sm-0',
            },
          ]
          this.show = false
        })
        .catch(error =>
          // eslint-disable-next-line
          console.log(error))
    },
  },
}
</script>
